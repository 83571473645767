// core components
// import Dashboard from "views/admin/Dashboard.js";
// import Icons from "views/admin/Icons.js";
import Login from "views/auth/Login.js";
// import Maps from "views/admin/Maps.js";
// import Profile from "views/admin/Profile.js";
// import Register from "views/auth/Register.js";
// import Tables from "views/admin/Tables.js";
// @material-ui/icons components
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import Dns from "@material-ui/icons/Dns";
// import FlashOn from "@material-ui/icons/FlashOn";
// import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
// import Grain from "@material-ui/icons/Grain";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Palette from "@material-ui/icons/Palette";
// import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import VpnKey from "@material-ui/icons/VpnKey";
// import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import PeopleAltTwoTone from "@material-ui/icons/PeopleAltTwoTone";
// import CardMembershipTwoTone from "@material-ui/icons/CardMembershipTwoTone";
import DashboardTwoTone from "@material-ui/icons/DashboardTwoTone";
import FileCopyTwoTone from "@material-ui/icons/FileCopyTwoTone";
// import AccountCircleTwoTone from "@material-ui/icons/AccountCircleTwoTone";

// Imports By Lughut
// import Dashboard from "pages/admin/Dashboard.js";
import WorkFlow from "pages/admin/WorkFlow.js";
// import Users from "pages/admin/Users.js";
// import Levels from "pages/admin/Levels.js";
import WBSCodes from "pages/pm/WBSCodes.js";
import Tasks from "pages/pm/Tasks.js";
import Reports from "pages/admin/Reports.js";
import MasterData from "pages/admin/MasterData.js";
// import AddNewUser from "pages/admin/AddNewUser.js";
// import ResetPassword from "pages/admin/ResetPassword.js";
// import AddNewTask from "pages/admin/AddNewTask.js";
// import EditProfile from "pages/admin/EditProfile.js";
// import AddNewLevel from "pages/admin/AddNewLevel.js";
// import AddNewWBSCode from "pages/admin/AddNewWBSCode.js";

var routes = [
  {
    path: "/workflow",
    name: "Workflow",
    icon: PeopleAltTwoTone,
    iconColor: "Info",
    component: WorkFlow,
    layout: "/pm",
  },
  // {
  //   path: "/users",
  //   name: "Users",
  //   icon: PeopleAltTwoTone,
  //   iconColor: "Primary",
  //   component: Users,
  //   layout: "/admin",
  // },
  // {
  //   path: "/levels",
  //   name: "Levels",
  //   icon: CardMembershipTwoTone,
  //   iconColor: "Warning",
  //   component: Levels,
  //   layout: "/admin",
  // },
  {
    path: "/wbs_codes",
    name: "Projects",
    icon: DashboardTwoTone,
    iconColor: "WarningLight",
    component: WBSCodes,
    layout: "/pm",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: DashboardTwoTone,
    iconColor: "WarningLight",
    component: Tasks,
    layout: "/pm",
  },
  {
    path: "/reports",
    name: "Weekly Reports",
    icon: FileCopyTwoTone,
    iconColor: "Error",
    component: Reports,
    layout: "/pm",
  },
  {
    path: "/master_data",
    name: "Master Data",
    icon: Tv,
    iconColor: "Info",
    component: MasterData,
    layout: "/pm",
  },
  {
    divider: true,
  },
  {
    title: "Others",
  },
  // {
  //   path: "/user/add",
  //   name: "Add New User",
  //   icon: PeopleAltTwoTone,
  //   // iconColor: "Primary",
  //   component: AddNewUser,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user/password",
  //   name: "Reset User Password",
  //   icon: VpnKeyTwoToneIcon,
  //   // iconColor: "Primary",
  //   component: ResetPassword,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user/edit",
  //   name: "Edit Profile",
  //   icon: AccountCircleTwoTone,
  //   // iconColor: "Primary",
  //   component: EditProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/level/add",
  //   name: "Add New Level",
  //   icon: CardMembershipTwoTone,
  //   // iconColor: "Primary",
  //   component: AddNewLevel,
  //   layout: "/admin",
  // },
  // {
  //   path: "/wbs/add",
  //   name: "Add New Project",
  //   icon: FileCopyTwoTone,
  //   // iconColor: "Primary",
  //   component: AddNewWBSCode,
  //   layout: "/admin",
  // },
  // {
  //   path: "/task/add",
  //   name: "Add New Task",
  //   icon: FileCopyTwoTone,
  //   // iconColor: "Primary",
  //   component: AddNewTask,
  //   layout: "/admin",
  // },
  {
    path: "/",
    name: "Logout",
    icon: VpnKey,
    component: Login,
    layout: "/auth",
  },
];
export default routes;
