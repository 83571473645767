import React, { useState, useEffect } from "react";
// import React, { useEffect, useState } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// import { Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
// import DataGrid from "@material-ui/core/Grid";
import Grid from "@material-ui/core/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";

import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@mui/material/Typography";

// import * as fs from "fs";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios.js";
import {
  add,
  sub,
  endOfWeek,
  startOfWeek,
  isWithinInterval,
  areIntervalsOverlapping,
  eachDayOfInterval,
} from "date-fns";
import { useStateValue } from "../../StateProvider";

// import { useDemoData } from '@mui/x-data-grid-generator';
import { createTheme, darken, lighten } from "@mui/material/styles";

// core components
import Header from "components/Headers/Header.js";
// import EditIcon from "@material-ui/icons/Edit";

import {
  chartOptions,
  parseOptions,
  // chartExample1,
  // chartExample2,
} from "variables/charts.js";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";

// import componentStyles from "assets/theme/views/admin/dashboard.js";
import { ButtonGroup, TextField } from "@material-ui/core";

const defaultTheme = createTheme();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            float: "right",
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles(
  (theme) => {
    const getBackgroundColor = (color) =>
      theme.palette.mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color) =>
      theme.palette.mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

    return {
      root: {
        "& .super-app-theme--Parent": {
          backgroundColor: "rgba(0, 110, 255, 0.1)",
          "&:hover": {
            backgroundColor: "rgba(0, 110, 255, 0.05)",
          },
        },
        "& .super-app-theme--Total": {
          backgroundColor: "rgba(50, 50, 50, 0.1)",
          "&:hover": {
            backgroundColor: "#FFF",
          },
        },
        "& .super-app-theme--PartiallyFilled": {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.warning.main
            ),
          },
        },
        "& .super-app-theme--Rejected": {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
      },
    };
  },
  { defaultTheme }
);

var json2xls = require("json2xls");

let Today = null;
let FirstDayOfTheWeek = null;
let LastDayOfTheWeek = null;
let DaysInTheWeek = null;
let SelectedWorkflowId = null;

let ProgressDumper = null;
let GrandTotal = 0;
let GrandGrandTotal = 0;

// let ListOfEmployees = null;

let Employees = null;
let Workflow = null;
let Progress = null;
let Projects = null;
let Levels = null;

let hrs_expense = "hrs";
let group_by = "employees";

function Dashboard() {
  const [{ UserProfile }, dispatch] = useStateValue();
  const classes = useStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  const [FirstDay, setFirstDay] = useState(null);
  const [LastDay, setLastDay] = useState(null);

  const [ConfirmationModalVisibility, setConfirmationModalVisibility] =
    React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setConfirmationModalVisibility(true);
  };
  const handleClose = () => {
    setConfirmationModalVisibility(false);
  };

  const GetValueOfRespectedInput = (index) => {
    try {
      let RawProgress = ProgressDumper.filter(
        (item) => item.Workflow_id == SelectedWorkflowId
      )[0].Progress;
      let RawProgressKeys = Object.keys(RawProgress).map((pp) => [
        pp,
        pp.split("GMT")[0],
      ]);
      let NewProgress = {};
      RawProgressKeys.forEach((ll) => {
        NewProgress[ll[1]] = RawProgress[ll[0]];
      });
      // console.log(DaysInTheWeek[index]);
      return NewProgress[DaysInTheWeek[index]];
    } catch (e) {
      return 0;
    }
  };

  const RespectedCorresspondedExists = (index) => {
    try {
      // console.log(
      //   Object.entries(
      //     ProgressDumper.filter(
      //       (item) => item.Workflow_id == SelectedWorkflowId
      //     )[0].Progress
      //   ).map((ii) => [ii[0].split("GMT")[0], ii[1]])
      // );
      return Object.entries(
        ProgressDumper.filter(
          (item) => item.Workflow_id == SelectedWorkflowId
        )[0].Progress
      )
        .map((ii) => [ii[0].split("GMT")[0], ii[1]])
        .filter((item) => item[0] == DaysInTheWeek[index]).length == 1
        ? true
        : false;
    } catch (e) {
      return false;
    }
  };

  const [EditModalVisibility, setEditModalVisibility] = React.useState(false);
  const handleEditOpen = (id) => {
    // console.log("its clicked");
    SelectedWorkflowId = id;
    setEditModalVisibility(EditModalVisibility ? false : true);
  };
  const handleEditClose = async (flag = false) => {
    if (flag) {
      Today = new Date();
      FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      });
      let req_obj = {};
      Object.keys(DaysInTheWeek).forEach((key) => {
        // console.log(">>>>>>>>>>>", key);
        if (RespectedCorresspondedExists(key)) {
          req_obj["Progress." + DaysInTheWeek[key]] = document.getElementById(
            DaysInTheWeek[key]
          ).value;
        }
      });
      req_obj = {
        Workflow_id: SelectedWorkflowId,
        Progress: {
          ...req_obj,
        },
      };
      console.log("Object Formulated for Timesheet update", req_obj);
      // return;

      try {
        console.log("Request to update Progress Initiating ... ");
        const Response = await axios.post("/progress/update", req_obj);
        console.log("Response Recieved", Response.data);
        console.log("Request successfull");
        window.location.href = window.location.origin + "/#/employee/user/edit";
        setTimeout(() => {
          window.location.href =
            window.location.origin + "/#/employee/timesheet";
        }, 5);
      } catch (e) {
        console.log("Request Failed!", e);
      }
    }
    setEditModalVisibility(false);
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (index) => {
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const [SelectedUserIndex, setSelectedUserIndex] = useState(null);

  const [columns, setcolumns] = useState([
    // { field: "id", headerName: "Sr", width: 50 },
    // {
    //   field: "ProjectName",
    //   headerName: "Projects",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "ProjectName",
      headerName: "Projects",
      width: 250,
      editable: false,
    },
    {
      field: "mon",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "tue",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "wed",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "thu",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "fri",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "sat",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "sun",
      headerName: "MMM DD, YYYY",
      width: 110,
      editable: false,
    },
    {
      field: "Total",
      headerName: "Total",
      width: 180,
      editable: false,
    },
  ]);

  const [rows, setrows] = useState([
    // {
    //   id: 1,
    //   ProjectName: " • Vacation",
    //   mon: "8.00",
    //   tue: "0.00",
    //   wed: "0.00",
    //   thu: "0.00",
    //   fri: "0.00",
    //   sat: "0.00",
    //   sun: "0.00",
    //   Total: "8.00",
    // },
  ]);
  const [ListOfEmployees, setListOfEmployees] = useState(null);

  const getFormattedDate = (date) => {
    date = new Date(date).toDateString().split(" ");
    date.pop();
    date = date.join(" ");
    // console.log(date);
    return date;
  };

  const DownloadExcelFile = async () => {
    const ff = rows.map((mm) => {
      const {
        _id,
        id,
        __v,
        RowType,
        Password,
        Role,
        Contact,
        Email,
        Level,
        Name,
        Site,
        Date_A,
        Date_B,
        Employee_id,
        Project_id,
        TaskType,
        actions,
        ...ret
      } = mm;

      let yy = {
        ProjectName: ret.ProjectName,
        Total: ret.Total,
      };
      Object.keys(ret).forEach((ss) => {
        if (ss != "ProjectName" && ss != "Total")
          yy[getFormattedDate(ss)] = ret[ss];
      });

      return yy;
    });

    // console.log(FirstDay, LastDay);

    const pp = {
      fields: (DaysInTheWeek = eachDayOfInterval({
        start: FirstDay,
        end: LastDay,
      }).map((oo) => getFormattedDate(oo.toString().split("GMT")[0]))),
      data: ff,
    };

    window
      .open(
        (await axios.get("/")).config.baseURL +
          "download/excel/" +
          JSON.stringify(pp),
        "_blank"
      )
      .focus();
  };

  const CalSumInArray = (Workflow_id) => {
    try {
      Today = new Date();
      // FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      // LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      });

      DaysInTheWeek = DaysInTheWeek.map((qq) => qq.toString().split("GMT")[0]);

      console.log("fgfgfg", DaysInTheWeek);

      SelectedWorkflowId = Workflow_id;
      let sum = 0;
      DaysInTheWeek.forEach((day, index) => {
        sum += RespectedCorresspondedExists(index)
          ? GetValueOfRespectedInput(index) * 1
          : 0;
      });
      return sum;
    } catch (e) {
      return 0;
    }
  };

  const FormulateUserReport = async (StartDay, EndDay) => {
    try {
      setrows([]);
      Today = new Date();
      // FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      // LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: FirstDayOfTheWeek,
      }).map((oo) => oo.toString().split("GMT")[0]);

      StartDay = StartDay == null ? FirstDayOfTheWeek : StartDay;
      EndDay = EndDay == null ? LastDayOfTheWeek : EndDay;

      console.log("Requesting Employees ...");
      let EmployeesResponse = await axios.get("/get/users/employee");
      Employees = EmployeesResponse.data.map((item) => {
        return {
          ...item,
          id: item._id,
          ProjectName: item.Name + " (" + item.Site + ")",
          // RowType: "Parent",
        };
      });
      console.log("Progress successfully recieved!", Employees);

      console.log("Requesting Progress ...");
      let Progress = await axios.get("/get/progress");
      ProgressDumper = Progress.data
        .map((mm) => {
          let pro = mm.Progress;
          let npro = {};
          Object.keys(pro).forEach((pp) => {
            console.log(pp, StartDay, EndDay);
            if (
              isWithinInterval(new Date(pp.toString().split("GMT")[0]), {
                start: StartDay,
                end: EndDay,
              })
            ) {
              npro[pp] = pro[pp];
            } else console.log("discarded");
          });

          let obj = {
            ...mm,
            Progress: npro == {} ? false : npro,
          };
          // console.log("Progress", obj);
          return obj;
        })
        .filter((bb) => bb != false);
      Progress = ProgressDumper;
      console.log("Progress successfully recieved!", ProgressDumper);

      console.log("Requesting Levels ...");
      let _Levels = await axios.get("/get/levels");
      Levels = _Levels.data;
      console.log("Levels successfully recieved!");

      console.log("Requesting Projects ...");
      let _Projects = await axios.get("/get/projects");
      console.log("Projects successfully recieved!");

      let master_data = [];

      const GetValueOfRespectedParent = (emp_id, key) => {
        try {
          console.log("in it");
          const aa = master_data.filter(
            (item) => item.RowType == "Parent" && item.id == emp_id
          )[0][key];
          // const aa = master_data.filter(
          //   (item) => item.RowType == "Parent" && item.Employee_id == emp_id
          // )[0][key];
          // console.log(key, aa, master_data);
          return aa;
          // return 0;
        } catch (e) {
          return 0;
        }
      };

      for (let index = 0; index < Employees.length; index++) {
        let emp = Employees[index];
        let id = emp.id;
        console.log("Evaluating", emp.ProjectName);

        console.log("Requesting Workflow ...");
        let Response = await axios.post("/get/workflow", {
          Employee_id: id,
        });
        Workflow = Response.data;
        console.log("Response Recieved", Response.data);
        GrandTotal = 0;
        Response.data = Response.data
          .filter(
            (item) =>
              Object.keys(
                ProgressDumper.filter((as) => as.Workflow_id == item._id)
                  .length > 0 &&
                  ProgressDumper.filter((as) => as.Workflow_id == item._id)[0]
                    .Progress
              ).length == 0
                ? false
                : true &&
                  // (() => {
                  //   console.log(
                  //     Object.keys(
                  //     ProgressDumper.filter((as) => as.Workflow_id == item._id)[0]
                  //       .Progress
                  //     ).length
                  //   );
                  //   return true;
                  // })() &&
                  item.TaskType != "null" &&
                  areIntervalsOverlapping(
                    {
                      start: FirstDayOfTheWeek,
                      end: LastDayOfTheWeek,
                    },
                    {
                      start: new Date(item.Date_A),
                      end: new Date(item.Date_B),
                    },
                    {
                      inclusive: true,
                    }
                  )
            // isWithinInterval(new Date(), {
            //   start: new Date(item.Date_A),
            //   end: new Date(item.Date_B),
            //   // start: new Date(item.Date_A.toString().split("GMT")[0]),
            //   // end: new Date(item.Date_B.toString().split("GMT")[0]),
            // })
          )
          .map((item) => {
            // console.log(
            //   "£££££££££",
            //   ProgressDumper.filter((as) => as.Workflow_id == item._id)
            // );
            // Getting Levels, Sites etc ...
            // console.log(
            //   "=-=-=---=-=-=-=",
            //   item.Employee_id,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Level,
            // Levels.filter(
            //   (bb) =>
            //     bb._id ==
            //     Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
            // )[0][
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
            //   "onsite"
            //     ? "OnSitePerHr"
            //     : "OffShorePerHr"
            // ] * 1
            // );
            FirstDayOfTheWeek = StartDay;
            LastDayOfTheWeek = EndDay;
            GrandTotal +=
              CalSumInArray(item._id) *
              (hrs_expense == "hrs"
                ? 1
                : Levels.filter(
                    (bb) =>
                      bb._id ==
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Level
                  )[0][
                    Employees.filter((aa) => aa.id == item.Employee_id)[0]
                      .Site == "onsite"
                      ? "OnSitePerHr"
                      : "OffShorePerHr"
                  ] * 1);

            const Spe = ProgressDumper.filter(
              (prog) => prog.Workflow_id == item._id
            )[0].Progress;
            const SpeProgress = {};
            Object.keys(Spe)
              .map((oo) => [oo, oo.toString().split("GMT")[0]])
              .forEach((ll) => {
                // console.log(ll);
                SpeProgress[ll[1]] = Spe[ll[0]];
              });

            let res = [];
            // Object.keys(
            //   ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
            //     .Progress
            // )
            Object.keys(SpeProgress).forEach((ax) => {
              // console.log(
              //   "()()()",
              //   ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
              //     .Progress[
              //     Object.keys(
              //       ProgressDumper.filter(
              //         (prog) => prog.Workflow_id == item._id
              //       )[0].Progress
              //     )
              //       .map((uu) => [uu.toString().split("GMT")[0], uu])
              //       .filter((tt) => tt[0] == ax)[0][1]
              //   ]
              // );
              res[ax] =
                ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
                  .Progress[
                  Object.keys(
                    ProgressDumper.filter(
                      (prog) => prog.Workflow_id == item._id
                    )[0].Progress
                  )
                    .map((uu) => [uu.toString().split("GMT")[0], uu])
                    .filter((tt) => tt[0] == ax)[0][1]
                ] *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1);
            });
            console.log("][][][][", SpeProgress, res);
            return {
              ...item,
              id: item._id,
              actions: {
                show: true,
                Workflow_id: item._id,
              },
              // RowType: "Parent",
              ProjectName:
                " • " +
                _Projects.data.filter((proj) => proj._id == item.Project_id)[0]
                  .ProjectName +
                " - " +
                item.TaskType,
              // ...Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
              //   .Progress,
              ...res,
              Total:
                CalSumInArray(item._id) *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1),
            };
          });
        let zz = {};
        DaysInTheWeek = eachDayOfInterval({
          start: StartDay,
          end: EndDay,
        }).map((oo) => oo.toString().split("GMT")[0]);
        // console.log('fgfgfg',DaysInTheWeek)
        Object.keys(DaysInTheWeek).forEach((key, index) => {
          let col_sum = 0;
          ProgressDumper.forEach((work) => {
            SelectedWorkflowId = work.Workflow_id;
            if (
              Response.data.filter((item) => item._id == work.Workflow_id)
                .length == 0
            )
              return;
            let item = Workflow.filter((xc) => xc._id == work.Workflow_id)[0];
            col_sum += RespectedCorresspondedExists(index)
              ? GetValueOfRespectedInput(index) *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1)
              : 0;

            // console.log(
            //   "=-=-=---=-=-=-=",
            //   item.Employee_id,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Level,
            //   Levels.filter(
            //     (bb) =>
            //       bb._id ==
            //       Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
            //   )[0][
            //     Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
            //     "onsite"
            //       ? "OnSitePerHr"
            //       : "OffShorePerHr"
            //   ] * 1
            // );
          });
          zz[DaysInTheWeek[key]] = col_sum;
        });
        DaysInTheWeek = zz;
        master_data.push(
          // emp
          // ...Response.data,
          {
            // actions: {
            //   show: false,
            //   Workflow_id: null,
            // },
            ...emp,
            // id: "kjasdhaslkh",
            // ProjectName: emp.ProjectName,
            RowType: "Parent",
            ...zz,
            Total: GrandTotal,
          },
          ...Response.data
        );
        // console.log("Response Filtered", Response.data);
      }

      // Today = new Date();
      // FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      // LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: StartDay,
        end: EndDay,
      }).map((oo) => oo.toString().split("GMT")[0]);

      GrandGrandTotal = 0;

      let zz = {};
      Object.keys(DaysInTheWeek).forEach((key, index) => {
        let col_sum = 0;
        master_data
          .filter((item) => item.RowType != "Parent")
          .forEach((work) => {
            col_sum +=
              work[DaysInTheWeek[key]] !== undefined
                ? work[DaysInTheWeek[key]] * 1
                : 0;
          });
        zz[DaysInTheWeek[key]] = col_sum;
        GrandGrandTotal += col_sum;
      });
      console.log("zz", zz);

      master_data.push({
        id: "kjasdhaslkh",
        ProjectName: "Total",
        RowType: "Parent",
        ...zz,
        Total: GrandGrandTotal,
      });

      setrows(master_data);
      return;

      // console.log("Requesting Workflow ...");
      // let Response = await axios.post("/get/workflow", {
      //   Employee_id: id,
      // });
      // Workflow = Response.data;
      // console.log("Response Recieved", Response.data);
      // GrandTotal = 0;
      // Response.data = Response.data
      //   .filter(
      //     (item) =>
      //       isWithinInterval(new Date(Today), {
      //         start: new Date(item.Date_A),
      //         end: new Date(item.Date_B),
      //       }) && item.TaskType != "null"
      //   )
      //   .map((item) => {
      //     GrandTotal += CalSumInArray(item._id);
      //     return {
      //       ...item,
      //       id: item._id,
      //       actions: {
      //         show: true,
      //         Workflow_id: item._id,
      //       },
      //       RowType: "Parent",
      //       ProjectName:
      //         " • " +
      //         _Projects.data.filter((proj) => proj._id == item.Project_id)[0]
      //           .ProjectName +
      //         " - " +
      //         item.TaskType,
      //       ...Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
      //         .Progress,
      //       Total: CalSumInArray(item._id),
      //     };
      //   });
      // let zz = {};
      // Object.keys(DaysInTheWeek).forEach((key, index) => {
      //   let col_sum = 0;
      //   Progress.data.forEach((work) => {
      //     SelectedWorkflowId = work.Workflow_id;
      //     if (
      //       Response.data.filter((item) => item._id == work.Workflow_id)
      //         .length == 0
      //     )
      //       return;
      //     col_sum += RespectedCorresspondedExists(index)
      //       ? GetValueOfRespectedInput(index) * 1
      //       : 0;
      //   });
      //   zz[DaysInTheWeek[key]] = col_sum;
      // });
      // DaysInTheWeek = zz;
      // Response.data = [
      //   ...Response.data,
      //   {
      //     actions: {
      //       show: false,
      //       Workflow_id: null,
      //     },
      //     id: "kjasdhaslkh",
      //     ProjectName: "Total",
      //     RowType: "Child",
      //     ...zz,
      //     Total: GrandTotal,
      //   },
      // ];
      // console.log("Response Filtered", Response.data);
      // setrows(Response.data);
      // console.log("Request successfull!");
    } catch (e) {
      console.log("Request Failed!", e);
    }
  };

  const FormulateProjectReport = async (StartDay, EndDay) => {
    Today = new Date();
    FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
    LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
    DaysInTheWeek = eachDayOfInterval({
      start: FirstDayOfTheWeek,
      end: LastDayOfTheWeek,
    }).map((oo) => oo.toString().split("GMT")[0]);

    StartDay = StartDay == null ? FirstDayOfTheWeek : StartDay;
    EndDay = EndDay == null ? LastDayOfTheWeek : EndDay;

    // FirstDayOfTheWeek = StartDay;
    // LastDayOfTheWeek = EndDay;
    // DaysInTheWeek = eachDayOfInterval({
    //   start: FirstDayOfTheWeek,
    //   end: LastDayOfTheWeek,
    // }).map((oo) => oo.toString().split("GMT")[0]);

    // console.log("========", StartDay, EndDay);
    try {
      let master_data = [];
      setrows([]);

      console.log("Requesting Projects ...");
      let AllProjects = await axios.get("/get/projects");
      AllProjects = AllProjects.data.map((aa) => {
        return {
          ...aa,
          id: aa._id,
          RowType: "Parent",
        };
      });
      console.log("Projects successfully recieved!");

      // console.log(AllProjects);
      console.log("Requesting Employees ...");
      let AllEmployeesResponse = await axios.get("/get/users/employee");
      Employees = AllEmployeesResponse.data.map((item) => {
        return {
          ...item,
          id: item._id,
          // ProjectName: item.Name + " (" + item.Site + ")",
          // RowType: "Parent",
        };
      });
      console.log("Employees successfully recieved!", Employees);

      console.log("Requesting Progress ...");
      let AllProgress = await axios.get("/get/progress");
      AllProgress = AllProgress.data
        .map((mm) => {
          let pro = mm.Progress;
          let npro = {};
          Object.keys(pro).forEach((pp) => {
            // console.log(pp, StartDay, EndDay);
            if (
              isWithinInterval(new Date(pp.toString().split("GMT")[0]), {
                start: new Date(StartDay),
                end: new Date(EndDay),
              })
            ) {
              npro[pp] = pro[pp];
            } else console.log("%c discarded", "color:red");
          });

          let obj = {
            ...mm,
            Progress: npro == {} ? false : npro,
          };
          // console.log("Progress", obj);
          return obj;
        })
        .filter((bb) => bb != false);
      ProgressDumper = AllProgress;
      // Progress = Progress.data;
      console.log("Progress successfully recieved!", AllProgress.data);

      console.log("Requesting Levels ...");
      let AllLevels = await axios.get("/get/levels");
      Levels = AllLevels.data;
      console.log("Levels successfully recieved!");

      console.log("Requesting Workflow ...");
      let AllWorkFlow = await axios.get("/get/workflow");
      Workflow = AllWorkFlow.data;
      console.log("Response Recieved", Workflow);

      FirstDayOfTheWeek = StartDay;
      LastDayOfTheWeek = EndDay;
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      }).map((oo) => oo.toString().split("GMT")[0]);

      Workflow = Workflow.filter(
        (item) =>
          Object.keys(
            ProgressDumper.filter((as) => as.Workflow_id == item._id).length >
              0 &&
              ProgressDumper.filter((as) => as.Workflow_id == item._id)[0]
                .Progress
          ).length == 0
            ? false
            : true &&
              // (() => {
              //   console.log(
              //     Object.keys(
              //     ProgressDumper.filter((as) => as.Workflow_id == item._id)[0]
              //       .Progress
              //     ).length
              //   );
              //   return true;
              // })() &&
              item.TaskType != "null" &&
              areIntervalsOverlapping(
                {
                  start: FirstDayOfTheWeek,
                  end: LastDayOfTheWeek,
                },
                {
                  start: new Date(item.Date_A),
                  end: new Date(item.Date_B),
                },
                {
                  inclusive: true,
                }
              )
        //  &&
        // isWithinInterval(new Date(), {
        //   start: new Date(item.Date_A.toString().split("GMT")[0]),
        //   end: new Date(item.Date_B.toString().split("GMT")[0]),
        // })
      ).map((item) => {
        if (item.TaskType == "null")
          return {
            ...item,
          };
        // console.log(item);

        FirstDayOfTheWeek = StartDay;
        LastDayOfTheWeek = EndDay;
        DaysInTheWeek = eachDayOfInterval({
          start: FirstDayOfTheWeek,
          end: LastDayOfTheWeek,
        }).map((oo) => oo.toString().split("GMT")[0]);

        GrandTotal +=
          CalSumInArray(item._id) *
          (hrs_expense == "hrs"
            ? 1
            : Levels.filter(
                (bb) =>
                  bb._id ==
                  Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
              )[0][
                Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
                "onsite"
                  ? "OnSitePerHr"
                  : "OffShorePerHr"
              ] * 1);

        const Spe = ProgressDumper.filter(
          (prog) => prog.Workflow_id == item._id
        )[0].Progress;
        const SpeProgress = {};
        Object.keys(Spe)
          .map((oo) => [oo, oo.toString().split("GMT")[0]])
          .forEach((ll) => {
            // console.log(ll);
            SpeProgress[ll[1]] = Spe[ll[0]];
          });
        // console.log(SpeProgress);

        let res = [];

        Object.keys(SpeProgress).forEach((ax) => {
          // console.log(
          //   "()()()",
          //   ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
          //     .Progress[
          //     Object.keys(
          //       ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
          //         .Progress
          //     )
          //       .map((uu) => [uu.toString().split("GMT")[0], uu])
          //       .filter((tt) => tt[0] == ax)[0][1]
          //   ]
          // );
          res[ax] =
            ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
              .Progress[
              Object.keys(
                ProgressDumper.filter((prog) => prog.Workflow_id == item._id)[0]
                  .Progress
              )
                .map((uu) => [uu.toString().split("GMT")[0], uu])
                .filter((tt) => tt[0] == ax)[0][1]
            ] *
            (hrs_expense == "hrs"
              ? 1
              : Levels.filter(
                  (bb) =>
                    bb._id ==
                    Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
                )[0][
                  Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
                  "onsite"
                    ? "OnSitePerHr"
                    : "OffShorePerHr"
                ] * 1);
        });
        // console.log(res);
        // console.log({
        //   ...item,
        //   id: item._id,
        //   // actions: {
        //   //   show: true,
        //   //   Workflow_id: item._id,
        //   // },
        //   ...res,
        //   Total:
        //     CalSumInArray(item._id) *
        //     (hrs_expense == "hrs"
        //       ? 1
        //       : Levels.filter(
        //           (bb) =>
        //             bb._id ==
        //             Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
        //         )[0][
        //           Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
        //           "onsite"
        //             ? "OnSitePerHr"
        //             : "OffShorePerHr"
        //         ] * 1),
        // });
        return {
          ...item,
          id: item._id,
          // actions: {
          //   show: true,
          //   Workflow_id: item._id,
          // },
          ...res,
          Total:
            CalSumInArray(item._id) *
            (hrs_expense == "hrs"
              ? 1
              : Levels.filter(
                  (bb) =>
                    bb._id ==
                    Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
                )[0][
                  Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
                  "onsite"
                    ? "OnSitePerHr"
                    : "OffShorePerHr"
                ] * 1),
        };
      });
      // setrows(Workflow);
      // return;

      AllProjects.forEach((proj) => {
        // console.log(proj);
        // if workflow progress is empty return
        // if (Workflow.filter((as) => as._id == proj._id).length == 0) return;
        master_data.push(proj);
        // console.log(
        //   "[][][][][][",
        //   AllProgress.filter((as) => as._id == proj.)
        // );
        // console.log(Workflow.filter((ww) => ww.Project_id == proj._id));
        Workflow.forEach((ww) => {
          console.log(
            "*********",
            ww.TaskType,
            new Date(ww.Date_A),

            FirstDayOfTheWeek,
            LastDayOfTheWeek
          );
        });
        // console.log(
        //   "***********",

        //   Workflow.filter(
        //     (ww) =>
        // Object.keys(
        //   AllProgress.filter((as) => as.Workflow_id == ww._id)[0].Progress
        // ).length == 0
        //   ? false
        //   : true &&
        // ww.Project_id == proj._id && ww.TaskType != "null"
        // &&
        // isWithinInterval(new Date(ww.Date_A), {
        //   start: FirstDayOfTheWeek,
        //   end: LastDayOfTheWeek,
        // })
        // )
        // );
        master_data = [
          ...master_data,
          ...Workflow.filter(
            (ww) =>
              // (() => {
              //   console.log(
              //     "[]][][][",
              //     Object.keys(
              //       AllProgress.filter((as) => as.Workflow_id == ww._id)[0]
              //         .Progress
              //     ).length
              //   );

              //   return true;
              // })() &&
              Object.keys(
                AllProgress.filter((as) => as.Workflow_id == ww._id)[0].Progress
              ).length == 0
                ? false
                : true && ww.Project_id == proj._id && ww.TaskType != "null"
            // &&
            // isWithinInterval(new Date(ww.Date_A), {
            //   start: FirstDayOfTheWeek,
            //   end: LastDayOfTheWeek,
            // })
          ).map((gg) => {
            return {
              ...gg,
              id: gg._id,
              ProjectName:
                Employees.filter((ee) => ee._id == gg.Employee_id)[0].Name +
                " - " +
                Workflow.filter((rr) => rr._id == gg._id)[0].TaskType,
            };
          }),
        ];
      });

      setrows(master_data);
      return;

      /*
      console.log("Requesting Employees ...");
      let EmployeesResponse = await axios.get("/get/users/employee");
      Employees = EmployeesResponse.data.map((item) => {
        return {
          ...item,
          id: item._id,
          ProjectName: item.Name + " (" + item.Site + ")",
          // RowType: "Parent",
        };
      });
      console.log("Progress successfully recieved!", Employees);

      console.log("Requesting Progress ...");
      let Progress = await axios.get("/get/progress");
      ProgressDumper = Progress.data;
      // Progress = Progress.data;
      console.log("Progress successfully recieved!", Progress.data);

      console.log("Requesting Levels ...");
      let _Levels = await axios.get("/get/levels");
      Levels = _Levels.data;
      console.log("Levels successfully recieved!");

      console.log("Requesting Projects ...");
      let _Projects = await axios.get("/get/projects");
      console.log("Projects successfully recieved!");

      // let master_data = [];

      const GetValueOfRespectedParent = (emp_id, key) => {
        try {
          console.log("in it");
          const aa = master_data.filter(
            (item) => item.RowType == "Parent" && item.id == emp_id
          )[0][key];
          // const aa = master_data.filter(
          //   (item) => item.RowType == "Parent" && item.Employee_id == emp_id
          // )[0][key];
          // console.log(key, aa, master_data);
          return aa;
          // return 0;
        } catch (e) {
          return 0;
        }
      };

      for (let index = 0; index < Employees.length; index++) {
        let emp = Employees[index];
        let id = emp.id;
        console.log("Evaluating", emp.ProjectName);

        console.log("Requesting Workflow ...");
        let Response = await axios.post("/get/workflow", {
          Employee_id: id,
        });
        Workflow = Response.data;
        console.log("Response Recieved", Response.data);
        GrandTotal = 0;
        Response.data = Response.data
          .filter(
            (item) =>
              isWithinInterval(new Date(), {
                start: new Date(item.Date_A),
                end: new Date(item.Date_B),
              }) && item.TaskType != "null"
          )
          .map((item) => {
            // Getting Levels, Sites etc ...
            // console.log(
            //   "=-=-=---=-=-=-=",
            //   item.Employee_id,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Level,
            // Levels.filter(
            //   (bb) =>
            //     bb._id ==
            //     Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
            // )[0][
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
            //   "onsite"
            //     ? "OnSitePerHr"
            //     : "OffShorePerHr"
            // ] * 1
            // );
            GrandTotal +=
              CalSumInArray(item._id) *
              (hrs_expense == "hrs"
                ? 1
                : Levels.filter(
                    (bb) =>
                      bb._id ==
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Level
                  )[0][
                    Employees.filter((aa) => aa.id == item.Employee_id)[0]
                      .Site == "onsite"
                      ? "OnSitePerHr"
                      : "OffShorePerHr"
                  ] * 1);
            let res = [];
            Object.keys(
              Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
                .Progress
            ).forEach((ax) => {
              res[ax] =
                Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
                  .Progress[ax] *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1);
            });
            // console.log(res);
            return {
              ...item,
              id: item._id,
              actions: {
                show: true,
                Workflow_id: item._id,
              },
              // RowType: "Parent",
              ProjectName:
                " • " +
                _Projects.data.filter((proj) => proj._id == item.Project_id)[0]
                  .ProjectName +
                " - " +
                item.TaskType,
              // ...Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
              //   .Progress,
              ...res,
              Total:
                CalSumInArray(item._id) *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1),
            };
          });
        let zz = {};
        Object.keys(DaysInTheWeek).forEach((key, index) => {
          let col_sum = 0;
          Progress.data.forEach((work) => {
            SelectedWorkflowId = work.Workflow_id;
            if (
              Response.data.filter((item) => item._id == work.Workflow_id)
                .length == 0
            )
              return;
            let item = Workflow.filter((xc) => xc._id == work.Workflow_id)[0];
            col_sum += RespectedCorresspondedExists(index)
              ? GetValueOfRespectedInput(index) *
                (hrs_expense == "hrs"
                  ? 1
                  : Levels.filter(
                      (bb) =>
                        bb._id ==
                        Employees.filter((aa) => aa.id == item.Employee_id)[0]
                          .Level
                    )[0][
                      Employees.filter((aa) => aa.id == item.Employee_id)[0]
                        .Site == "onsite"
                        ? "OnSitePerHr"
                        : "OffShorePerHr"
                    ] * 1)
              : 0;

            // console.log(
            //   "=-=-=---=-=-=-=",
            //   item.Employee_id,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Site,
            //   Employees.filter((aa) => aa.id == item.Employee_id)[0].Level,
            //   Levels.filter(
            //     (bb) =>
            //       bb._id ==
            //       Employees.filter((aa) => aa.id == item.Employee_id)[0].Level
            //   )[0][
            //     Employees.filter((aa) => aa.id == item.Employee_id)[0].Site ==
            //     "onsite"
            //       ? "OnSitePerHr"
            //       : "OffShorePerHr"
            //   ] * 1
            // );
          });
          zz[DaysInTheWeek[key]] = col_sum;
        });
        DaysInTheWeek = zz;
        master_data.push(
          // emp
          // ...Response.data,
          {
            // actions: {
            //   show: false,
            //   Workflow_id: null,
            // },
            ...emp,
            // id: "kjasdhaslkh",
            // ProjectName: emp.ProjectName,
            RowType: "Parent",
            ...zz,
            Total: GrandTotal,
          },
          ...Response.data
        );
        // console.log("Response Filtered", Response.data);
      }

      Today = new Date();
      FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      });

      GrandGrandTotal = 0;

      let zz = {};
      Object.keys(DaysInTheWeek).forEach((key, index) => {
        let col_sum = 0;
        master_data
          .filter((item) => item.RowType != "Parent")
          .forEach((work) => {
            col_sum +=
              work[DaysInTheWeek[key]] !== undefined
                ? work[DaysInTheWeek[key]] * 1
                : 0;
          });
        zz[DaysInTheWeek[key]] = col_sum;
        GrandGrandTotal += col_sum;
      });
      console.log("zz", zz);

      master_data.push({
        id: "kjasdhaslkh",
        ProjectName: "Total",
        RowType: "Parent",
        ...zz,
        Total: GrandGrandTotal,
      });

      setrows(master_data);
      */
    } catch (e) {
      console.log("Request Failed!", e);
    }
  };

  const DecideFormulation = (StartDay = null, EndDay = null) =>
    group_by == "employees"
      ? FormulateUserReport(StartDay, EndDay)
      : FormulateProjectReport(StartDay, EndDay);

  useEffect(() => {
    (async () => {
      setFirstDay(startOfWeek(new Date(), { weekStartsOn: 1 }));
      setLastDay(endOfWeek(new Date(), { weekStartsOn: 1 }));
      console.log("=============>", LastDay);

      console.log("Days in the week =>", DaysInTheWeek);
      Today = new Date();
      FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      }).map((oo) => oo.toString().split("GMT")[0]);
      setcolumns([
        {
          field: "ProjectName",
          headerName: "Projects",
          width: 250,
          editable: false,
        },
        {
          field: DaysInTheWeek[0],
          headerName: getFormattedDate(DaysInTheWeek[0]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[1],
          headerName: getFormattedDate(DaysInTheWeek[1]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[2],
          headerName: getFormattedDate(DaysInTheWeek[2]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[3],
          headerName: getFormattedDate(DaysInTheWeek[3]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[4],
          headerName: getFormattedDate(DaysInTheWeek[4]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[5],
          headerName: getFormattedDate(DaysInTheWeek[5]),
          width: 110,
          editable: false,
        },
        {
          field: DaysInTheWeek[6],
          headerName: getFormattedDate(DaysInTheWeek[6]),
          width: 110,
          editable: false,
        },
        {
          field: "Total",
          headerName: "Total",
          width: 180,
          editable: false,
        },
      ]);
      // console.log(DaysInTheWeek);
      try {
        console.log("Requesting Employees ...");
        let Employees = await axios.get("/get/users/employee");
        // ListOfEmployees = Employees.data;
        setListOfEmployees(Employees.data);
        console.log("Employees data successfully recieved!", Employees.data);

        // FormulateUserReport(Employees.data[0]._id);
        DecideFormulation();
        setSelectedUserIndex(0);

        return;
        console.log("Requesting Progress ...");
        let Progress = await axios.get("/get/progress");
        ProgressDumper = Progress.data;
        console.log("Progress successfully recieved!");

        console.log("Requesting Projects ...");
        let Projects = await axios.get("/get/projects");
        console.log("Projects successfully recieved!");

        console.log("Requesting Workflow ...");
        let Response = await axios.post("/get/workflow", {
          Employee_id: UserProfile._id,
        });
        console.log("Response Recieved", Response.data);
        Response.data = Response.data
          .filter((item) =>
            isWithinInterval(new Date(Today), {
              start: new Date(item.Date_A),
              end: new Date(item.Date_B),
            })
          )
          .map((item) => {
            GrandTotal += CalSumInArray(item._id);
            return {
              ...item,
              id: item._id,
              actions: {
                show: true,
                Workflow_id: item._id,
              },
              ProjectName:
                " • " +
                Projects.data.filter((proj) => proj._id == item.Project_id)[0]
                  .ProjectName,
              ...Progress.data.filter((prog) => prog.Workflow_id == item._id)[0]
                .Progress,
              Total: CalSumInArray(item._id),
            };
          });
        let zz = {};
        Object.keys(DaysInTheWeek).forEach((key, index) => {
          let col_sum = 0;
          Progress.data.forEach((work) => {
            SelectedWorkflowId = work.Workflow_id;
            col_sum += RespectedCorresspondedExists(index)
              ? GetValueOfRespectedInput(index) * 1
              : 0;
          });
          zz[DaysInTheWeek[key]] = col_sum;
        });
        DaysInTheWeek = zz;
        Response.data = [
          ...Response.data,
          {
            actions: {
              show: false,
              Workflow_id: null,
            },
            id: "kjasdhaslkh",
            ProjectName: "Total",
            ...zz,
            Total: GrandTotal,
          },
        ];
        console.log("Response Filtered", Response.data);
        setrows(Response.data);
        console.log("Request successfull!");
      } catch (e) {
        console.log("Request, Failed", e);
      }
    })();
  }, []);

  const updateWeekDaysInTable = () => {
    console.log("Initiating process to update week days in table");
    DaysInTheWeek = eachDayOfInterval({
      start: FirstDayOfTheWeek,
      end: LastDayOfTheWeek,
    }).map((oo) => oo.toString().split("GMT")[0]);
    setcolumns([
      {
        field: "ProjectName",
        headerName: "Projects",
        width: 250,
        editable: false,
      },
      {
        field: DaysInTheWeek[0],
        headerName: getFormattedDate(DaysInTheWeek[0]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[1],
        headerName: getFormattedDate(DaysInTheWeek[1]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[2],
        headerName: getFormattedDate(DaysInTheWeek[2]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[3],
        headerName: getFormattedDate(DaysInTheWeek[3]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[4],
        headerName: getFormattedDate(DaysInTheWeek[4]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[5],
        headerName: getFormattedDate(DaysInTheWeek[5]),
        width: 110,
        editable: false,
      },
      {
        field: DaysInTheWeek[6],
        headerName: getFormattedDate(DaysInTheWeek[6]),
        width: 110,
        editable: false,
      },
      {
        field: "Total",
        headerName: "Total",
        width: 180,
        editable: false,
      },
    ]);
  };

  const ToggleWeek = (inc = false) => {
    console.log("Toggling Dates");
    if (inc) {
      FirstDayOfTheWeek = add(FirstDay, {
        days: 7,
      });
      setFirstDay(FirstDayOfTheWeek);

      LastDayOfTheWeek = add(LastDay, {
        days: 7,
      });
      setLastDay(LastDayOfTheWeek);
    } else {
      FirstDayOfTheWeek = sub(FirstDay, {
        days: 7,
      });
      setFirstDay(FirstDayOfTheWeek);

      LastDayOfTheWeek = sub(LastDay, {
        days: 7,
      });
      setLastDay(LastDayOfTheWeek);
    }
    console.log(FirstDay, LastDay);
    // updateWeekDaysInTable();
    updateWeekDaysInTable();
    DecideFormulation(FirstDayOfTheWeek, LastDayOfTheWeek);
  };

  const HeaderActions = () => {
    return (
      <div>
        <ButtonGroup variant="outlined">
          <Button size="small" onClick={() => ToggleWeek(false)}>
            <KeyboardArrowLeft size={50} />
          </Button>
          <Button size="small">
            <KeyboardArrowRight size={30} onClick={() => ToggleWeek(true)} />
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  return (
    <>
      {/* Edit Modal */}
      {DaysInTheWeek != null && ProgressDumper != null && (
        <div>
          {(() => {
            Today = new Date();
            FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
            LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
            DaysInTheWeek = eachDayOfInterval({
              start: FirstDayOfTheWeek,
              end: LastDayOfTheWeek,
            });
            try {
              {
                /* console.log("======>", GetValueOfRespectedInput(0)); */
              }
              {
                /* RespectedCorresspondedExists(0);
              RespectedCorresspondedExists(1);
              RespectedCorresspondedExists(2);
              RespectedCorresspondedExists(3);
              RespectedCorresspondedExists(4);
              RespectedCorresspondedExists(5);
              RespectedCorresspondedExists(6); */
              }
            } catch (e) {
              console.log("Failed", e);
            }
            console.log("Day in the week", DaysInTheWeek);
          })()}
          <BootstrapDialog
            onClose={() => handleEditClose(false)}
            aria-labelledby="customized-dialog-title"
            open={EditModalVisibility}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => handleEditClose(false)}
            >
              Edit Timesheet
            </BootstrapDialogTitle>
            <DialogContent dividers>
              {RespectedCorresspondedExists(0) && (
                <TextField
                  id={DaysInTheWeek[0]}
                  label={getFormattedDate(DaysInTheWeek[0])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(0)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(1) && (
                <TextField
                  id={DaysInTheWeek[1]}
                  label={getFormattedDate(DaysInTheWeek[1])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(1)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(2) && (
                <TextField
                  id={DaysInTheWeek[2]}
                  label={getFormattedDate(DaysInTheWeek[2])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(2)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(3) && (
                <TextField
                  id={DaysInTheWeek[3]}
                  label={getFormattedDate(DaysInTheWeek[3])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(3)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(4) && (
                <TextField
                  id={DaysInTheWeek[4]}
                  label={getFormattedDate(DaysInTheWeek[4])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(4)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(5) && (
                <TextField
                  id={DaysInTheWeek[5]}
                  label={getFormattedDate(DaysInTheWeek[5])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(5)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
              {RespectedCorresspondedExists(6) && (
                <TextField
                  id={DaysInTheWeek[6]}
                  label={getFormattedDate(DaysInTheWeek[6])}
                  variant="outlined"
                  defaultValue={GetValueOfRespectedInput(6)}
                  style={{ width: "100%", marginBottom: 20 }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleEditClose(true)}>
                Update Timesheet
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      )}
      {/* Confirmation Modal */}
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={ConfirmationModalVisibility}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Confirm Deleting the Level?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              By Confirming, the level will be permanently deleted and you'll
              not be able to restore it.
              <br />
              Note: It may effect the users that are assigned the same level.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              No, Don't Delete!
            </Button>
            <Button onClick={handleClose} autoFocus>
              Confrim
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box component="span" color={theme.palette.gray[600]}>
                    {FirstDay == null || LastDay == null
                      ? "Loading"
                      : "( " +
                        getFormattedDate(FirstDay) +
                        " - " +
                        getFormattedDate(LastDay) +
                        " )"}
                  </Box>
                }
                subheader={
                  LastDay != null &&
                  isWithinInterval(new Date(), {
                    start: new Date(FirstDay),
                    end: new Date(LastDay),
                  })
                    ? "This week"
                    : "Custom Week"
                }
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  component: Box,
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
                action={<HeaderActions />}
              ></CardHeader>
              <CardContent>
                <Box position="relative">
                  <Grid container>
                    <Grid item xs={5} xl={10}>
                      <Select
                        defaultValue={group_by}
                        onChange={(e) => {
                          group_by = e.target.value;
                          DecideFormulation(FirstDay, LastDay);
                        }}
                      >
                        <MenuItem value={"employees"}>
                          Group by Employees
                        </MenuItem>
                        <MenuItem value={"projects"}>
                          Group by Projects
                        </MenuItem>
                      </Select>

                      <div
                        style={{
                          width: 20,
                          height: 1,
                          display: "inline-block",
                        }}
                      ></div>

                      <Select
                        defaultValue={hrs_expense}
                        onChange={(e) => {
                          hrs_expense = e.target.value;
                          DecideFormulation(FirstDay, LastDay);
                        }}
                      >
                        <MenuItem value={"hrs"}>Hours Utilized</MenuItem>
                        <MenuItem value={"expense"}>Estimated Expense</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={5} xl={2}>
                      <Button variant="outlined" onClick={DownloadExcelFile}>
                        Export Data
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div style={{ width: "100%" }} className={classes.root}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      // pageSize={5}
                      // rowsPerPageOptions={[5]}
                      checkboxSelection={false}
                      disableSelectionOnClick
                      autoHeight
                      // rowHeight={35}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) => {
                        // console.log(params);
                        if (params.row.RowType == "Parent")
                          return `super-app-theme--Parent`;
                      }}
                    />
                    {/* <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  /> */}
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>{" "}
      </Container>
    </>
  );
}

export default Dashboard;
