import React, { useState, useEffect } from "react";
// import React, { useEffect, useState } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// import { Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
// import DataGrid from "@material-ui/core/Grid";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@mui/material/Typography";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios.js";
import {
  endOfWeek,
  startOfWeek,
  // isWithinInterval,
  areIntervalsOverlapping,
  eachDayOfInterval,
  // format,
} from "date-fns";
import { useStateValue } from "../../StateProvider";

// core components
import Header from "components/Headers/Header.js";
// import EditIcon from "@material-ui/icons/Edit";

import {
  chartOptions,
  parseOptions,
  // chartExample1,
  // chartExample2,
} from "variables/charts.js";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";

import componentStyles from "assets/theme/views/admin/dashboard.js";
// import { TextField } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            float: "right",
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles(componentStyles);

let Today = null;
let FirstDayOfTheWeek = null;
let LastDayOfTheWeek = null;
let DaysInTheWeek = null;
let SelectedWorkflowId = null;

let ProgressDumper = null;
let GrandTotal = 0;

let SelectedProject = "null";
let SelectedTask = "null";
let date_a = "null";
let date_b = "null";

let ProjectList = [];
let WorkflowList = [];
let TaskList = [];

function Dashboard() {
  const [{ UserProfile }, dispatch] = useStateValue();
  const classes = useStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  const [ConfirmationModalVisibility, setConfirmationModalVisibility] =
    React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setConfirmationModalVisibility(true);
  };
  const handleClose = () => {
    setConfirmationModalVisibility(false);
  };

  const [AssignProjectModalVisibility, setAssignProjectModalVisibility] =
    useState(false);
  const ToggleAssignProjectModal = async (flag = false) => {
    if (flag) {
      date_a = WorkflowList.filter(
        (aa) =>
          aa.Project_id == SelectedProject &&
          (aa.TaskType == "" || aa.TaskType == "null")
      )[0].date_a;
      date_b = WorkflowList.filter(
        (aa) =>
          aa.Project_id == SelectedProject &&
          (aa.TaskType == "" || aa.TaskType == "null")
      )[0].date_b;

      // console.log(date_a, date_b);
      // return;

      // console.log(assignment_employee, assignment_project, assignment_type);
      let ListOfDatesOfProgress = Object.assign(
        {},
        eachDayOfInterval({
          start: new Date(date_a),
          end: new Date(date_b),
        })
      );
      let zz = {};
      Object.keys(ListOfDatesOfProgress).forEach((key) => {
        zz[ListOfDatesOfProgress[key]] = 0.0;
      });
      ListOfDatesOfProgress = zz;

      let req_obj = {
        Employee_id: UserProfile._id,
        Project_id: SelectedProject,
        TaskType: SelectedTask,
        Date_A: date_a,
        Date_B: date_b,
      };
      console.log("Object Formulated for Project assignment =>", req_obj);
      console.log("Project assignment initiating ...");
      const Response = await axios.post("/workflow/create", req_obj);
      console.log("Response recieved", Response.data);
      console.log("Request successfull!!");

      // console.log("Object Formulated for Project workflow management =>", {
      //   Workflow_id: "Response.data",
      //   Progress: ListOfDatesOfProgress,
      // });
      console.log("Progress initiating with Empty Data ...");
      const _Response = await axios.post("/progress/create", {
        Workflow_id: Response.data,
        Progress: ListOfDatesOfProgress,
      });
      console.log("Response recieved", _Response.data);
      console.log("Request successfull!!");

      window.location.href = window.location.origin + "/#/employee/user/edit";
      setTimeout(() => {
        window.location.href = window.location.origin + "/#/employee/wbs_codes";
      }, 10);
    }
    setAssignProjectModalVisibility(
      AssignProjectModalVisibility ? false : true
    );
  };

  const GetValueOfRespectedInput = (index) => {
    try {
      return ProgressDumper.filter(
        (item) => item.Workflow_id == SelectedWorkflowId
      )[0].Progress[[DaysInTheWeek[index]]];
    } catch (e) {
      return 0;
    }
  };

  const RespectedCorresspondedExists = (index) => {
    try {
      return Object.entries(
        ProgressDumper.filter(
          (item) => item.Workflow_id == SelectedWorkflowId
        )[0].Progress
      ).filter((item) => item[0] == DaysInTheWeek[index]).length == 1
        ? true
        : false;
    } catch (e) {
      return false;
    }
  };

  const [EditModalVisibility, setEditModalVisibility] = React.useState(false);
  const handleEditOpen = (id) => {
    // console.log("its clicked");
    SelectedWorkflowId = id;
    setEditModalVisibility(EditModalVisibility ? false : true);
  };
  const handleEditClose = async (flag = false) => {
    if (flag) {
      Today = new Date();
      FirstDayOfTheWeek = startOfWeek(Today, { weekStartsOn: 1 });
      LastDayOfTheWeek = endOfWeek(Today, { weekStartsOn: 1 });
      DaysInTheWeek = eachDayOfInterval({
        start: FirstDayOfTheWeek,
        end: LastDayOfTheWeek,
      });
      let req_obj = {};
      Object.keys(DaysInTheWeek).forEach((key) => {
        // console.log(">>>>>>>>>>>", key);
        if (RespectedCorresspondedExists(key)) {
          req_obj["Progress." + DaysInTheWeek[key]] = document.getElementById(
            DaysInTheWeek[key]
          ).value;
        }
      });
      req_obj = {
        Workflow_id: SelectedWorkflowId,
        Progress: {
          ...req_obj,
        },
      };
      console.log("Object Formulated for Timesheet update", req_obj);
      // return;

      try {
        console.log("Request to update Progress Initiating ... ");
        const Response = await axios.post("/progress/update", req_obj);
        console.log("Response Recieved", Response.data);
        console.log("Request successfull");
        window.location.href = window.location.origin + "/#/employee/user/edit";
        setTimeout(() => {
          window.location.href =
            window.location.origin + "/#/employee/timesheet";
        }, 5);
      } catch (e) {
        console.log("Request Failed!", e);
      }
    }
    setEditModalVisibility(false);
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (index) => {
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const [columns, setcolumns] = useState([
    {
      field: "ProjectName",
      headerName: "Project Name",
      width: 250,
      editable: false,
    },
    {
      field: "Date_A",
      headerName: "Assignment Date",
      width: 150,
      editable: false,
    },
    {
      field: "Date_B",
      headerName: "Deadline",
      width: 150,
      editable: false,
    },
  ]);

  const [rows, setrows] = useState([
    // {
    //   id: 1,
    //   ProjectName: " • Vacation",
    // },
  ]);

  const getFormattedDate = (date) => {
    date = new Date(date).toDateString().split(" ");
    date.pop();
    date = date.join(" ");
    // console.log(date);
    return date;
  };

  const CalSumInArray = (Workflow_id) => {
    try {
      SelectedWorkflowId = Workflow_id;
      let sum = 0;
      DaysInTheWeek.forEach((day, index) => {
        sum += RespectedCorresspondedExists(index)
          ? GetValueOfRespectedInput(index) * 1
          : 0;
      });
      return sum;
    } catch (e) {
      return 0;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        console.log("Requesting Tasks List ...");
        let TasksList = await axios.get("/get/tasks");
        TaskList = TasksList.data;
        console.log(
          "Tasks List successfully recieved and saved!",
          TasksList.data
        );

        console.log("Requesting Projects List ...");
        let ProjectsList = await axios.get("/get/projects");
        ProjectList = ProjectsList.data;
        console.log(
          "Projects List successfully recieved and saved!",
          ProjectsList.data
        );

        console.log("Requesting assigned Projects ...");
        let Projects = await axios.get("/get/workflow");
        Projects.data = Projects.data
          .filter(
            (project) =>
              project.Employee_id == UserProfile._id &&
              (project.TaskType == "" || project.TaskType == "null") &&
              areIntervalsOverlapping(
                {
                  start: startOfWeek(new Date()),
                  end: endOfWeek(new Date()),
                },
                {
                  start: startOfWeek(new Date(project.Date_A)),
                  end: endOfWeek(new Date(project.Date_B)),
                },
                {
                  inclusive: true,
                }
              )
            // isWithinInterval(new Date(), {
            //   start: new Date(project.Date_A),
            //   end: new Date(project.Date_B),
            // })
          )
          .map((project) => {
            return {
              ...project,
              id: project._id,
              ProjectName: ProjectList.filter(
                (proj) => proj._id == project.Project_id
              )[0].ProjectName,
              date_a: project.Date_A,
              date_b: project.Date_B,
              Date_A: getFormattedDate(project.Date_A),
              Date_B: getFormattedDate(project.Date_B),
            };
          });
        WorkflowList = Projects.data;
        console.log("Assigned Projects successfully recieved and saved!");

        console.log("Response Filtered", Projects.data);
        setrows(Projects.data);
        console.log("Request successfull!");
      } catch (e) {
        console.log("Request, Failed", e);
      }
    })();
  }, []);

  const HeaderActions = () => {
    return (
      <div>
        <ButtonGroup
          variant="outlined"
          size="small"
          title="Assign project to employee"
          onClick={() => ToggleAssignProjectModal()}
        >
          <Button>Add to Timeline</Button>
        </ButtonGroup>
      </div>
    );
  };

  return (
    <>
      {/* Add WorkFlow Modal */}
      {
        <div>
          <BootstrapDialog
            onClose={() => ToggleAssignProjectModal()}
            aria-labelledby="customized-dialog-title"
            open={AssignProjectModalVisibility}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => ToggleAssignProjectModal()}
              style={{ width: 550 }}
            >
              Add to Timeline
            </BootstrapDialogTitle>
            <DialogContent dividers style={{ height: 200 }}>
              <Select
                labelId="assignment_employee"
                id="assignment_employee"
                defaultValue={SelectedProject}
                label="Project"
                variant={"standard"}
                onChange={(e) => (SelectedProject = e.target.value)}
                style={{
                  borderColor: "white",
                  backgroundColor: "white",
                  color: "black",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <MenuItem value={"null"}>Select Project</MenuItem>
                {WorkflowList != null &&
                  (() => {
                    const code = [];
                    for (let index = 0; index < WorkflowList.length; index++) {
                      code.push(
                        <MenuItem value={WorkflowList[index].Project_id}>
                          {WorkflowList[index].ProjectName}
                        </MenuItem>
                      );
                    }
                    console.log("code", code);
                    return code;
                  })()}
              </Select>

              <Select
                labelId="assignment_project"
                id="assignment_project"
                defaultValue={SelectedTask}
                label="Project"
                variant={"standard"}
                onChange={(e) => (SelectedTask = e.target.value)}
                style={{
                  borderColor: "white",
                  backgroundColor: "white",
                  color: "black",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <MenuItem value={"null"}>Select Task</MenuItem>
                {TaskList != null &&
                  (() => {
                    const code = [];
                    for (let index = 0; index < TaskList.length; index++) {
                      code.push(
                        <MenuItem value={TaskList[index].TaskName}>
                          {TaskList[index].TaskName}
                        </MenuItem>
                      );
                    }
                    console.log("code", code);
                    return code;
                  })()}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => ToggleAssignProjectModal(true)}>
                Add to Timeline
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      }
      {/* Confirmation Modal */}
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={ConfirmationModalVisibility}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Confirm Deleting the Level?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              By Confirming, the level will be permanently deleted and you'll
              not be able to restore it.
              <br />
              Note: It may effect the users that are assigned the same level.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              No, Don't Delete!
            </Button>
            <Button onClick={handleClose} autoFocus>
              Confrim
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box component="span" color={theme.palette.gray[600]}>
                    list of assigned projects
                  </Box>
                }
                subheader="Your Projects"
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  component: Box,
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
                action={<HeaderActions />}
              ></CardHeader>
              <CardContent>
                <Box position="relative">
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      // pageSize={5}
                      // rowsPerPageOptions={[5]}
                      autoHeight
                      checkboxSelection={false}
                      disableSelectionOnClick
                    />
                    {/* <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  /> */}
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>{" "}
      </Container>
    </>
  );
}

export default Dashboard;
